import { IsNotEmpty, IsOptional, IsString } from "class-validator";
import { SettingsEntity } from "../entity";

export class AddSettingsForEntityDto
{
    @IsString()
    @IsNotEmpty()
    configId!: string;
}

export class GetSettingsDto<E extends SettingsEntity = SettingsEntity>
{
    @IsOptional()
    settings?: E;
}