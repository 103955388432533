import { IsBoolean, IsEnum, IsOptional, IsString, Matches } from 'class-validator';
import { Entity } from '../entity/entity.js';
import { DataCollectionRecordType } from './record-type.js';

export class DataCollectionEntity extends Entity
{
    @IsString()
    @Matches(/^[a-z0-9]+\.[a-z0-9]+\.[a-z0-9]+$/)
    name!: string;

    @IsString()
    label!: string;

    @IsString()
    @IsOptional()
    description?: string;

    @IsEnum(DataCollectionRecordType)
    recordType!: DataCollectionRecordType;

    @IsBoolean()
    @IsOptional()
    monitorStatus?: boolean;

    constructor(baseEntity: Partial<DataCollectionEntity>)
    {
        super();
        if(baseEntity.id)
            this.id = baseEntity.id;
        if(baseEntity.name)
            this.name = baseEntity.name;
        if(baseEntity.recordType)
            this.recordType = baseEntity.recordType;
        this.description = baseEntity.description;
    }
}